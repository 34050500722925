const config = {
  siteName: 'Example Company',
  siteTitle: 'Example Company', // Site title.
  siteCopyright: 'Example Company LLC', // Used for copyright.
  siteTitleShort: 'Example', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Example Company', // Alternative site title for SEO.
  siteLogo: 'images/logo.png', // Logo used for SEO and manifest. Relative to /static
  siteLogoFromRoot: '/images/logo.png',
  siteUrl: 'https://examplecompany.com', // Domain of your website without pathPrefix.
  siteDescription: 'Example Company', // Website description used for RSS feeds/meta description tag.
  siteAuthor: 'Example Company', // Site author
  siteEmail: 'example@example.com',
  siteLocation: 'USA',
  siteAddress: '123 Example St',
  sitePhone: '123-123-1234',
  siteWorkingDays: 'M-F',
  siteWorkingHours: '9a - 5pm',
  siteSocialIG: 'https://instagram.com/example',
  favIcon: 'src/images/logo.png',
  baseUrl: 'https://examplecompany.com',
  pageTitle: 'Shop',
  menuTitle: 'Menu Title',
  subtitle: 'Example Company',
  description: 'Shop Example Company products',
  primaryColor: '#1C2D3E',
  secondaryColor: '#3E78BC',
  backgroundColor: '#fff',
};

module.exports = config;
